<template>
  <ValidationObserver ref="emailForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('common.mode')" :label-width="labelWidth">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="sendEmail"
            >
              <el-option
                v-for="option in [
                  { value: false, label: $t('common.download_directly')},
                  { value: true, label: $t('common.send_by_email')},
                ]"
                class="select-default text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.email')" :label-width="labelWidth" v-if="sendEmail">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="email"
            rules="required|email"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              v-model="emailLocal"
              type="text"
              class="mb-0"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
import {Select, Option} from 'element-ui';
import ListGroupItemComponent from "../ListGroupItemComponent";
import {mapGetters} from "vuex";

export default {
  name: "OctoTableExportForm",
  data() {
    return {
      emailLocal: '',
      sendEmail: false,
      labelWidth: 100
    }
  },
  components: {
    ListGroupItemComponent,
    [Option.name]: Option,
    [Select.name]: Select
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  mounted() {
    this.emailLocal = this.$_.cloneDeep(this.currentUser?.defaultEmail?.email) || '';
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.emailForm.validate()
          .then((success) => {
            if (success) {
              resolve({
                email: this.emailLocal,
                sendEmail: this.sendEmail
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
}
</script>

<style scoped>

</style>
