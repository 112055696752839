<template>
  <card card-body-classes="table-full-width">
    <h4 slot="header" v-if="title" class="card-title">{{ title }}</h4>
    <div class="container-fluid" v-if="showFilters">
      <collapse v-if="showFiltersCollapse" v-model="activeNames" class="mb-2">
        <collapse-item
          class="text-uppercase"
          name="filters"
          :title="`${$t('fields.filters')} (${appliedFiltersQuantity})`"
        >
          <div class="row">
            <slot name="custom-filters"></slot>
          </div>
        </collapse-item>
      </collapse>
      <div class="row align-items-end">
        <div class="col flex-grow-1" v-if="showQueryInput">
          <base-input
            :label="$t('fields.query')"
            v-model="query"
            type="text">
          </base-input>
        </div>
        <div class="col col-md-3" v-if="orderByItems.length > 0">
          <base-input :label="$t('datatable.order_by')">
            <el-select
              clearable
              class="select-default text-uppercase"
              v-model="orderBy"
            >
              <el-option
                v-for="item in orderByItems"
                class="select-default text-uppercase"
                :value="item"
                :label="$t('datatable.orders.' + item)"
                :key="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col col-md-2" v-if="orderByItems.length > 0">
          <base-input :label="$t('datatable.direction')">
            <el-select
              clearable
              class="select-default text-uppercase"
              v-model="direction"
            >
              <el-option
                v-for="item in ['asc', 'desc']"
                class="select-default text-uppercase"
                :value="item"
                :label="item"
                :key="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-auto form-group">
          <div class="d-flex">
            <base-button
              icon
              link
              class="ml-auto text-capitalize mb-0"
              :disabled="loading"
              @click="resetFilters"
            >
              <octo-icon icon="sync"/>
            </base-button>
            <base-button
              icon
              link
              class="ml-auto text-capitalize mb-0"
              :disabled="loading"
              @click="changeTableSettings"
            >
              <octo-icon icon="magnifying-glass"/>
            </base-button>
            <base-button v-if="enableExport"
                         :disabled="loading"
                         @click="openExportModal"
                         icon
                         link
                         class="ml-auto text-capitalize mb-0">
              <octo-icon icon="download"></octo-icon>
            </base-button>
            <base-button v-if="enableAdd"
                         @click="$emit('onAdd')"
                         icon
                         link
                         class="ml-auto text-capitalize mb-0">
              <octo-icon icon="add"/>
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <el-table
          :max-height="maxHeight"
          :style="{minHeight : tableItems.length ? '100px' : '200px' }"
          v-loading="loading"
          stripe
          :showHeader="showHeader"
          :data="tableItems">
          <el-table-column
            v-if="showId"
            fixed
            width="80"
            label="id"
            align="center"
          >
            <div slot-scope="{ row }">
              <div class="small">{{ row.id | udid }}</div>
            </div>
          </el-table-column>
          <el-table-column
            v-for="column in fields"
            :key="column.prop"
            :prop="column.prop"
            :width="column.width"
            :min-width="column.minWidth"
            :fixed="column.fixed ? column.fixed : false"
            :label="$t('datatable.' + column.label)"
            :align="column.align || 'left'"
          >
            <template slot-scope="scope">
              <slot v-if="column.slot" :name="column.prop" :row='scope.row'></slot>
              <span v-else>{{ scope.row[column.prop] }}</span>
            </template>

          </el-table-column>

          <template slot="empty">
            <no-data-component class="mt-3" v-show="!loading" :label="$t('datatable.no_result')"/>
          </template>

        </el-table>
      </div>
    </div>
    <div
      slot="footer" v-if="showPageSelect || (totalRows > rowsInPage)"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div v-if="showPageSelect">
        <base-input>
          <el-select
            class="select-default mb-1"
            v-model="rowsInPage"
            :placeholder="$t('common.per_page')"
            @change="updateRowsInpage"
          >
            <el-option
              class="select-default"
              v-for="item in perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </base-input>
        <p class="card-category">
          {{ $t('common.showing') }} {{ $t('common.from') }} {{ from + 1 }} {{ $t('common.to') }} {{ to }}
          {{ $t('common.of') }} {{ totalRows }} {{ $t('common.records') }}
        </p>
      </div>
      <base-pagination v-if="totalRows > rowsInPage"
                       class="pagination-no-border"
                       v-model="currentPage"
                       :per-page="rowsInPage"
                       :total="totalRows"
                       @input="updateCurrentPage"
      >
      </base-pagination>
    </div>
    <modal bodyClasses="px-2" centered :show.sync="showExportModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('common.export_mode') }}
      </h5>
      <octo-table-export-form ref="octoTableExportForm" :key="`octo-table-export-${exportModalKey}`"/>
      <template slot="footer">
        <base-button link @click="executeExport">
          <span class="text-uppercase">{{ $t('common.send') }}</span>
        </base-button>
      </template>
    </modal>
  </card>
</template>

<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {Collapse, CollapseItem} from "@/components"
import {mapActions, mapGetters, mapMutations} from 'vuex'
import BasePagination from "@/components/BasePagination";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import BaseAlert from "@/components/BaseAlert";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import NoDataComponent from "@/components/NoDataComponent";
import ListGroupItemComponent from "../ListGroupItemComponent";
import OctoTableExportForm from "./OctoTableExportForm";
import Modal from "@/components/Modal";

export default {
  name: "OctoTable",
  components: {
    OctoTableExportForm,
    ListGroupItemComponent,
    NoDataComponent,
    LabelThemeComponent,
    BaseAlert,
    OctoIcon,
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Collapse,
    CollapseItem,
    Modal
  },
  data() {
    return {
      currentPage: 1,
      rowsInPage: 10,
      perPageOptions: [5, 10, 15, 25, 50],
      tableItems: [],
      totalRows: 0,
      orderBy: null,
      direction: null,
      query: '',
      loading: false,
      filterResetting: false,
      showExportModal: false,
      activeNames: ["filters"],
      exportModalKey: 0
    }
  },
  props: {
    fields: {
      type: Array,
      require: true,
      default: () => []
    },
    orderByItems: {
      type: Array,
      default: () => []
    },
    showId: {
      type: Boolean,
      default: true
    },
    showQueryInput: {
      type: Boolean,
      default: true
    },
    showFilters: {
      type: Boolean,
      default: false
    },
    showFiltersCollapse: {
      type: Boolean,
      default: true
    },
    enableExport: {
      type: Boolean,
      default: false
    },
    enableExportEmail: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showPageSelect: {
      type: Boolean,
      default: true
    },
    action: {
      type: String,
      require: true
    },
    exportAction: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'table-' + Math.random().toString(36).substring(7)
    },
    title: {
      type: String,
      default: null
    },
    initRowsInPage: {
      type: Number,
      default: 15
    },
    filters: {
      type: Object,
      default: () => {
      }
    },
    enableStore: {
      type: Boolean,
      default: false
    },
    enableAdd: {
      type: Boolean,
      default: false
    }
  },

  async beforeMount() {
    this.rowsInPage = this.initRowsInPage;
    this.query = this.getStoreQuery(this.name);
    this.direction = this.getStoreDirection(this.name);
    this.orderBy = this.getStoreOrderBy(this.name);
    this.currentPage = this.getStoreCurrentPage(this.name) || 1;

    this.setFilter({
      name: this.name,
      data: {
        currentPage: this.currentPage,
        rowsInPage: this.rowsInPage,
        filters: this.getFilters(this.name) || {},
        query: this.query,
        orderBy: this.orderBy,
        direction: this.direction,
      }
    });

    const storedData = this.getDatatableData(this.name);
    if ((storedData?.totalRows || 0) && this.enableStore) {
      this.tableItems = storedData?.tableItems || [];
      this.totalRows = storedData?.totalRows || 0;
    } else {
      await this.getData();
    }

  },
  watch: {
    filters: async function (val) {
      if (this.filterResetting) {
        await this.getData();
        this.filterResetting = false;
      }
    },
  },

  computed: {
    ...mapGetters({
      getStoreQuery: 'common/datatableQuery',
      getStoreOrderBy: 'common/datatableOrderBy',
      getStoreDirection: 'common/datatableDirection',
      getStoreCurrentPage: 'common/datatableCurrentPage',
      getFilters: 'common/datatableFilters',
      getDatatableData: 'common/datatableData',
    }),

    to() {
      let highBound = this.from + this.rowsInPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.rowsInPage * (this.currentPage - 1);
    },

    appliedFiltersQuantity() {
      return this.$_.filter(Object.keys(this.filters), key => {
        return !this.$_.isEmpty(this.filters[key]) || typeof this.filters[key] === "boolean";
      }).length;
    },

    maxHeight() {
      return window.innerHeight - 400;
    }
  },

  methods: {

    ...mapMutations({
      setFilter: 'common/setDatatableFilter',
      setDatatableData: 'common/setDatatableData'
    }),

    ...mapActions({
      downloadFile: 'common/downloadFile'
    }),

    getData() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        const data = {
          currentPage: this.currentPage,
          rowsInPage: this.rowsInPage,
          filters: this.filters,
          query: this.query,
          orderBy: this.orderBy,
          orderDirection: this.direction
        }

        this.setFilter({
          name: this.name,
          data: data
        });

        this.setDatatableData({
          name: this.name,
          data: null
        });

        this.$api.post(this.action, data)
          .then((data) => {
            this.tableItems = data?.data?.collection?.length ? data.data.collection : [];
            this.totalRows = data.data.total;
            if (this.totalRows === 0) {
              this.$emit('onNoResults');
            }
            this.loading = false;
            this.setDatatableData({
              name: this.name,
              data: {
                tableItems: this.tableItems,
                totalRows: this.totalRows
              }
            });
            resolve(data)
          })
          .catch((err) => {
            this.loading = false;
            reject(err)
          });
      });

    },

    updateRowsInpage: async function () {
      this.currentPage = 1;
      await this.getData();
    },

    updateCurrentPage: async function () {
      await this.getData();
    },

    changeTableSettings() {
      this.tableItems = [];
      this.currentPage = 1;
      this.getData();
    },

    async resetFilters() {
      this.query = '';
      this.orderBy = null;
      this.direction = null;
      this.filterResetting = true;
      this.$emit('onResetFilters');
      this.$notify({type: 'info', message: this.$t('notifications.filters_cleared')});
      this.setDatatableData({
        name: this.name,
        data: null
      });
    },

    sendExport(data) {
      return new Promise((resolve, reject) => {
        this.$api.post(this.action + '/export-to-email', data)
          .then(() => {
            this.$notify({type: 'success', message: this.$t('notifications.export_email_sent')})
            resolve(data)
          })
          .catch(() => {
            reject(data)
            this.$notify({type: 'danger', message: this.$t('notifications.export_error')});
          })
      })
    },

    downloadExport(data) {
      return new Promise((resolve, reject) => {
        this.downloadFile(data)
          .then(() => {
            resolve(data)
          })
          .catch(() => {
            reject(data)
            this.$notify({type: 'danger', message: this.$t('notifications.export_error')});
          })
      })
    },

    openExportModal() {
      if (this.enableExportEmail) {
        this.exportModalKey++;
        this.showExportModal = true;
      } else {
        this.executeExport();
      }
    },

    async executeExport() {
      this.$fullLoading.show();
      try {

        const data = {
          url: this.exportAction || this.action + '/export',
          filename: this.name + '.xlsx',
          method: 'post',
          data: {
            filters: this.filters,
            query: this.query,
          },
        }

        if (this.enableExportEmail) {
          const result = await this.$refs.octoTableExportForm.validate();
          if (result.sendEmail) {
            data.data.export_email = result.email;
          }
        }

        if (data.data.export_email) {
          await this.sendExport(data.data);
        } else {
          await this.downloadExport(data);
        }

        this.$fullLoading.hide();
        this.showExportModal = false;
      } catch (e) {
        this.showExportModal = false;
        this.$fullLoading.hide();
        this.$notify({type: 'danger', message: this.$t('notifications.export_error')});
      }
    }
  }

}
</script>

<style scoped lang="scss">

.no-results {
  margin-top: 80px;
}
</style>
